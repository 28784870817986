.base2-container {
    display: flex;
    flex-direction: column;
    // background-image: url("../../../public/media/background2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    padding: 0 24px 24px 24px;

    .title-area {
        display: flex;
        position: relative;
        width: 100%;
        flex-direction: row;
        margin: 36px 0;
        height: 48px;

        .btn-group {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 572px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 27.5px;
            background: rgba(0, 0, 0, 0.30);
            backdrop-filter: blur(10px);
            color: #FFF;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            text-shadow: 0px 4px 21px rgba(0, 0, 0, 0.64);
            font-family: 庞门正道标题体免费版;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 2.56px;
        }


        .time {
            display: flex;
            align-items: center;
            margin-left: auto;
            color: rgba(0, 0, 0, 0.60);
            text-align: center;
            font-family: 庞门正道标题体免费版;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.6px;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        overflow: hidden;

        .left {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            background-image: url("../../../public/media/left-floor.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            // border-radius: 36px;
            border-radius: 12px;

            .hover-area {
                display: flex;
                position: absolute;
                width: 300px;
                height: 300px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
            }

            .btn-group-area {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: calc(100% - 28px);
                margin: 12px 0 0 16px;
                height: 48px;
                border-radius: 24px;
                background: rgba(0, 0, 0, 0.30);
                backdrop-filter: blur(10px);
                gap: 8px;

                .btn {
                    display: flex;
                    padding: 0px 10px 4px 10px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: rgba(0, 0, 0, 0.40);
                    color: #FFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    font-family: 庞门正道标题体免费版;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                }
            }

            .nan-area {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 107px;
                height: 91px;
                top: 12px;
                right: 12px;
            }

            .video-area {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                margin-top: 60px;
                flex-shrink: 0;
            }
        }

        .left-hover {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            background-image: url("../../../public/media/left-floor-hover.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            // border-radius: 36px;
            border-radius: 12px;

            .hover-area {
                display: flex;
                position: absolute;
                width: 300px;
                height: 300px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
            }

            .btn-group-area {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: calc(100% - 28px);
                margin: 12px 0 0 16px;
                height: 48px;
                border-radius: 24px;
                background: rgba(0, 0, 0, 0.30);
                backdrop-filter: blur(10px);
                gap: 8px;

                .btn {
                    display: flex;
                    padding: 0px 10px 4px 10px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: rgba(0, 0, 0, 0.40);
                    color: #FFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    font-family: 庞门正道标题体免费版;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                }
            }

            .nan-area {
                position: absolute;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 107px;
                height: 91px;
                top: 12px;
                right: 12px;
            }

            .video-area {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                margin-top: 60px;
                flex-shrink: 0;
            }
        }

        .middle {
            display: flex;
            flex-direction: column;
            width: 401px;
            height: 100%;
            border-radius: 16px;
            margin-left: 20px;
            flex-shrink: 0;
            gap: 3px;

            .monitor {
                background: rgba(0, 0, 0, 0.40);
                backdrop-filter: blur(10px);
                border-radius: 16px;
            }

            .item {
                display: flex;
                flex-direction: column;
                height: 33%;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.40);
                background: rgba(0, 0, 0, 0.30);
                backdrop-filter: blur(10px);
                width: 100%;
                flex-shrink: 0;
                padding: 8px;
                flex: 1 1 auto;

                .item-titles {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 8px;

                    .title {
                        display: flex;
                        height: 28px;
                        color: #FFF;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        font-family: 庞门正道标题体免费版;
                        font-size: 20px !important;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                        cursor: pointer;
                        margin-right: 16px;
                    }

                    .icon {
                        display: flex;
                        width: 24px;
                        height: 24px;
                        align-items: center;
                        margin-left: auto;
                        cursor: pointer;
                    }
                }

                .lunbo {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .carousel {
                        height: 60%;
                    }

                    .l-item {
                        position: relative;
                        width: 100%;
                        height: 100%;
                    }
                }


            }
        }

        .right {
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;

            display: flex;
            flex-direction: column;
            width: 400px;
            height: 100%;
            border-radius: 8px;
            flex-shrink: 0;
            backdrop-filter: blur(10px);
            margin-left: 20px;
            padding: 16px 20px;
        }

        .monitor-list {
            display: flex;
            flex-direction: column;
            width: 190px;
            // height: 100%;
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.40);
            background: rgba(0, 0, 0, 0.40);
            backdrop-filter: blur(10px);
            margin-left: 20px;
            overflow: hidden;

            .title {
                display: flex;
                margin-left: auto;
                margin-right: auto;
                height: 28px;
                color: #FFF;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: 庞门正道标题体免费版;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 16px;
                margin-bottom: 4px;
            }

            .item-list {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: auto;
                gap: 8px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
                    opacity: .2;
                    background-color: rgba(255, 255, 255, 0.1);
                    transition: background-color .3s;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(255, 255, 255, 0.8);
                }

                .item {
                    display: flex;
                    height: 24px;
                    padding: 4px 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: rgba(255, 255, 255, 0.20);
                    margin: 0 8px;

                    color: #FFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    font-family: "Hiragino Sans GB";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    cursor: pointer;
                }
            }
        }
    }


}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;